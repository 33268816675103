import styled from "styled-components";
import { ScrollArea } from "@mantine/core";

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 32px;
  padding: 48px 140px;
  position: relative;
  width: fit-content;
`;

export const Title = styled.h2`
  color: var(--gray-800);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 44.8px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
`;
export const ScrollableContent = styled(ScrollArea)`
  width: 100%;
  height: 458px;
  overflow-x: scroll;
  position: relative;
  left: 64px;
  ${(props) => props.theme.platform.mobile} {
    left: 0;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

import styled from "styled-components";
import { Button } from "../../components/Button";

export const Container = styled.div`
  background-color: rgba(241, 245, 249, 1);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const Title = styled.h2`
  font-size: 42px;
  color: rgba(19, 111, 136, 1);
  font-family: "Noto Sans Georgian";
`;

export const Text = styled.p`
  font-style: italic;
`;

export const Action = styled(Button)``;

export const questions = [
  {
    number: "01",
    title:
      "რა არის შიდა აზომვითი ნახაზი და რა შემთხვევებში არის აუცილებელი მისი მომზადება?",
    answer: `შიდა აზომვითი ნახაზი წარმოადგენს ნებისმიერი ტიპის შენობა-ნაგებობის შიდა ფართის გეგმას. ნახაზი გამოიყენება საჯარო რეესტრში ბინის, სხვენის, სარდაფის, ავტოფარეხის და ა.შ. სარეგისტრაციოთ, ასევე ბანკში ან სხვა ნებისმიერ ორგანოში წარსადგენად. შიდა ნახაზი ასახავს ოთახების და ტიხრების ზუსტ განლაგებას, კარ-ფანჯრის ადგილმდებარეობას მასზედ დატანილი ზომებით (როგორც ფართობი, ასევე სიგრძე-სიგანე). მისი მომზადება სავალდებულოა როდესაც: 
არასდროს არ ჩატარებულა აზომვითი სამუშაოები და შენობა არ არის დარეგისტრირებული - პირველადი რეგისტრაცია; 
უძრავი ქონების მფლობელს სურს იპოთეკური სესხის აღება და შენობის ფართობი არ არის ასახული ამონაწერში;
შენობა აზომილია ადრეულ წლებში და არ შეესაბამება დღეისათვის არსებულ სტანდარტს. (მაგ: ძველი წესით სველი წერტილების და აივნის ფართობი არ შედიოდა შენობის შიდა ფართში);
ხდება შენობის ორ ან მეტ ნაწილად დაყოფა - გამიჯვნა, ან პირიქით - გაერთიანება;
შენობას გააჩნია მიშენება და მფლობელს სურს, რომ ახალი დადგენილების თანახმად დაირეგისტრიროს მიშენებული ფართი;
კომუნიკაციებია შესაყვანი შენობაში;
იგეგმება ინტერიერის რეაბილიტაცია, გადაკეთება ან/და მოწყობა. არქიტექტორს, ხელოსანს, დიზაინერს ესაჭიროება არსებულის ზუსტი ზომები რათა წინასწარ შეძლონ მასალის, ავეჯის და შესაბამისად ხარჯების კალკულაცია.`,
  },
  {
    number: "02",
    title: `რა არის მიწის ნაკვეთის საკადასტრო აგეგმვითი/აზომვითი ნახაზი 
      და რა შემთხვევებში არის აუცილებელი მისი მომზადება?`,
    answer: `მიწის ნაკვეთის საკადასტრო აგეგმვითი/აზომვითი ნახაზი ადგენს და აღწერს მესაკუთრის მფლობელობაში არსებული მიწის საზღვრებს (ე.წ. წითელ ხაზებს) , ეს შეიძლება იყოს საკარმიდამო, სასოფლო-სამეურნეო/არასასოფლო-სამეურნეო 
ან კორპუსის მიმდებარე ტერიტორია, ასევე ასახავს არსებული შენობების განაშენიანების ფართს და ადგილმდებარეობას ლოკალურ კოორდინატებში. 
ყველა საკადასტრო აზომვითი ნახაზი არის ინდივიდუალური და გამოიყენება სხვადასხვა მიზნისთვის მაგ: 
პირველადი რეგისტრაციისთვის (როდესაც საერთოდ არ არის რეგისტრირებული მიწის ნაკვეთი საჯარო რეესტრში) 
საკადასტრო მონაცემების დაზუსტება/განახლებისთვის (როდესაც საკადასტრო გეგმის ელექტრონული ნახაზი არ არსებობს ან საკადასტრო გეგმაზე არ ფიქსირდება შენობის ნუმერაცია/სართულიანობა, ფართობი და ა.შ.) 
საკადასტრო მონაცემების კორექტირებისთვის (როდესაც არასწორად არის რეგისტრირებული მიწის ნაკვეთის საზღვრები ან მასზე არსებული შენობა-ნაგებობები) 
ნაკვეთის გამიჯვნა-გაერთიანებისთვის (როდესაც ხდება შენობის ორ ან მეტ ნაწილად დაყოფა - გამიჯვნა, ან პირიქით - გაერთიანება) 
ნაკვეთის აუქციონზე შესაძენად ან იჯარით ასაღებად `,
  },
  {
    number: "03",
    title:
      "რა არის დაკვალვა (საზღვრების დადგენა?) და რა შემთხვევაში მჭირდება ამ სერვისით სარგებლობა?",
    answer: `დაკვალვა არის საჯარო რეესტრში ან უკვე შედგენილ პროექტში არსებული მონაცემების რეალურ სივრცეში ჩვენება, ტერიტორიაზე წერტილების დასმა და დაფიქსირება. (WGS1984-UTM) 
დაკვალვის სერვისი გამოიყენება სხვადასხვა მიზნებისთვის მაგალითად: 
საკადასტრო წითელი ხაზების დაკვალვა; 
ზონალური ფენების დაკვალვა - თბილისში; 
ნაკვეთის შეძენამდე მისი ზუსტი ადგილ-მდებარეობის და საზღვრების განსაზღვრა; 
გეოდეზია და ტოპოგრაფია: 
ტოპოგრაფიული კვლევა (გეგმა); 
მიწის გამოყენების პირობების "გაპი"-ს მოსაპოვებლად; 
მშენებლობის ნებართვის მოსაპოვებლად; 
არქიტექტორული საქმიანობის განსახორციელებლად; 
პროექტით გათვალისწინებული ნაგებობების, გადატანა, დაკვალვა;`,
  },
  // ... other questions and answers
];

import { useState } from "react";
import {
  Button,
  Center,
  Divider,
  Drawer,
  Group,
  Space,
  Text,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router";
import { Logo } from "../../icons/Logo";
import { usePlatform } from "../../hooks";
import {
  StyledHeader,
  LeftSection,
  CenterSection,
  RightSection,
  MobileMenuButton,
  StyledDrawer,
  DrawerButton,
} from "./styles";

const menuItems = [
  { path: "home", label: "მთავარი" },
  { path: "services", label: "სერვისები" },
  { path: "about", label: "ჩვენ შესახებ" },
  { path: "news", label: "ბლოგი" },
  { path: "map-section", label: "საკადასტრო რუკა" },
];

export const Header = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const platform = usePlatform();

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const reloadPage = () => {
    window.scrollTo(0, 0);
    window.location.reload();
  };

  const scrollToSection = async (sectionId: string) => {
    if (location.pathname !== "/") {
      await navigate("/");
    }
    const section = document.getElementById(sectionId);
    setMenuOpen(false);
    if (section) {
      setTimeout(() => {
        window.scrollTo({
          top: section.offsetTop - 85,
          behavior: "smooth",
        });
      }, 500);
    }
  };

  return (
    <StyledHeader platform={platform}>
      <LeftSection>
        <div onClick={reloadPage}>
          <Logo className="" />
        </div>
      </LeftSection>
      {platform === "web" ? (
        <>
          <CenterSection>
            {menuItems.map((item) => (
              <Button
                key={item.path}
                variant={location.pathname === item.path ? "filled" : "subtle"}
                onClick={() => scrollToSection(item.path)}
                radius="8px"
                color="#000"
                styles={{
                  label: {
                    color: "var(--slate-800)",
                    fontFamily: "var(--body-m-regular-font-family)",
                    fontSize: "var(--body-m-regular-font-size)",
                    fontStyle: "var(--body-m-regular-font-style)",
                    fontWeight: "var(--body-m-regular-font-weight)",
                  },
                }}
              >
                {item.label}
              </Button>
            ))}
          </CenterSection>

          <RightSection>
            <Button
              variant="filled"
              onClick={() => scrollToSection(`contact`)}
              radius="8px"
              color="var(--gray-800)"
              styles={{
                label: {
                  color: "#ffffff",
                  fontFamily: "var(--body-m-regular-font-family)",
                  fontSize: "var(--body-m-regular-font-size)",
                  fontStyle: "var(--body-m-regular-font-style)",
                  fontWeight: "var(--body-m-regular-font-weight)",
                },
              }}
            >
              კონტაქტი
            </Button>
          </RightSection>
        </>
      ) : (
        <MobileMenuButton opened={menuOpen} onClick={toggleMenu} size="sm" />
      )}
      <Drawer
        opened={menuOpen}
        onClose={toggleMenu}
        padding="md"
        position="right"
        offset="64px 0"
        withCloseButton={false}
        styles={{
          body: {
            backgroundColor: "#fff",
            paddingBottom: 48,
          },
        }}
        transitionProps={{
          transition: "fade-down",
          duration: 200,
          timingFunction: "linear",
        }}
      >
        {menuItems.map((item) => (
          <>
            <Button
              key={item.path}
              fullWidth
              mt="sm"
              justify="flex-start"
              variant={location.pathname === item.path ? "filled" : "subtle"}
              onClick={() => {
                scrollToSection(item.path);
              }}
              radius="8px"
              color="#575757"
              styles={{
                label: {
                  color: "var(--text)",
                  fontFamily: "var(--body-m-regular-font-family)",
                  fontSize: "var(--body-m-regular-font-size)",
                  fontStyle: "var(--body-m-regular-font-style)",
                  fontWeight: "var(--body-m-regular-font-weight)",
                },
              }}
            >
              {item.label}
            </Button>

            <Divider color="#e2e2e2" mt="sm" />
          </>
        ))}

        <Space h="lg" />
        <Button
          fullWidth
          variant="filled"
          onClick={() => scrollToSection(`contact`)}
          color="var(--gray-800)"
          mt="xl"
          styles={{
            label: {
              color: "#fff",
              fontFamily: "var(--body-m-regular-font-family)",
              fontSize: "var(--body-m-regular-font-size)",
              fontStyle: "var(--body-m-regular-font-style)",
              fontWeight: "var(--body-m-regular-font-weight)",
            },
          }}
        >
          კონტაქტი
        </Button>

        <Center mt="xl">
          <Text size="sm">© 2010-2024 ყველა უფლება დაცულია.</Text>
        </Center>
      </Drawer>
    </StyledHeader>
  );
};

import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  sizes: {
    mobile: "480px",
    tablet: "768px",
    laptop: "1024px",
    desktop: "1200px",
  },
  platform: {
    mobile: `@media (max-width: 480px)`,
    tablet: `@media (max-width: 768px)`,
    laptop: `@media (max-width: 1024px)`,
    desktop: `@media (min-width: 1200px)`,
  },
};

import React, { useState } from "react";
import { Button } from "../Button";
import { questions } from "./FAQContent";
import { Question } from "./Question";
import { Container, Header, QuestionsWrapper, ViewMoreBtn } from "./styles";
import { usePlatform } from "../../hooks";

export const FAQSection = () => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const platform = usePlatform();

  const handleQuestionClick = (index) => {
    console.log(index);
    if (expandedQuestion === index) {
      setExpandedQuestion(null);
    } else {
      setExpandedQuestion(index);
    }
  };

  return (
    <Container platform={platform}>
      <Header platform={platform}>ხშირად დასმული კითხვები</Header>
      <QuestionsWrapper platform={platform}>
        {questions.map((question, index) => (
          <Question
            key={index}
            question={question}
            onClick={() => handleQuestionClick(index)}
            isExpanded={index === expandedQuestion}
          />
        ))}
      </QuestionsWrapper>
      <ViewMoreBtn text="იხილეთ მეტი" platform={platform} />
    </Container>
  );
};
console.log(ViewMoreBtn);

import React from "react";
import { Flex, Card, Image, Text } from "@mantine/core";
import { useNavigate } from "react-router";
import { useControlXState } from "@etlyn/control-x";
import { Container, ScrollableContent, Title } from "./styles";

export const BlogSection = ({ className, titleClassName }) => {
  const navigate = useNavigate();
  const { blogs } = useControlXState("blogs-core");

  return (
    <Container className={className}>
      <Title className={titleClassName}>
        გაეცანი მიწის აზომვასთან
        <br />
        დაკავშირებულ სიახლეებს
      </Title>
      <ScrollableContent type="never">
        <Flex direction="row" wrap="nowrap" gap={32}>
          {blogs
            .filter((blog) => blog.published)
            .map((blog, index) => {
              const doc = new DOMParser().parseFromString(
                blog.content,
                "text/html"
              );
              let parsedTextContent = doc.body.textContent || "";
              return (
                <Card
                  key={index}
                  w={300}
                  component="a"
                  onClick={() => navigate(`/blogs/${blog.id}`)}
                  shadow="sm"
                  radius="md"
                >
                  <Card.Section>
                    <Image
                      src={blog.cover_image_url}
                      fit="cover"
                      h={300}
                      w={300}
                      alt={blog.title}
                    />
                  </Card.Section>
                  <Text fw={500}>{blog.title}</Text>
                  <Text mt={12} truncate>
                    {parsedTextContent}
                  </Text>
                </Card>
              );
            })}
        </Flex>
      </ScrollableContent>
    </Container>
  );
};

import React from "react";

export enum ChevronDirection {
  LEFT = "left",
  DOWN = "down",
}

type props = {
  className?: string;
  direction?: string;
};

export const IconChevron = ({
  className,
  direction = ChevronDirection.LEFT,
}: props) => {
  return (
    <svg
      className={className}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {direction === ChevronDirection.LEFT && (
        <path
          className="path"
          d="M8.25 4.49994L15.75 11.9999L8.25 19.4999"
          stroke="#374151"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      )}
      {direction === ChevronDirection.DOWN && (
        <path
          className="path"
          d="M19.5 8.24997L12 15.75L4.5 8.24997"
          stroke="#374151"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      )}
    </svg>
  );
};

import React from "react";
import { useNavigate } from "react-router";
import { Action, Container, Text, Title } from "./styles";
export const ErrorPage = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Container>
      <Title>გვერდი არ მოიძებნა</Title>
      <Text>
        სამწუხაროდ, თქვენს მიერ მოთხოვნილი გვერდი არ მოიძებნა. გთხოვთ, სცადოთ
        თავიდან ან დაბრუნდეთ მთავარ გვერდზე.
      </Text>
      <Action onClick={() => navigate("/")} text="მთავარ გვერდზე დაბრუნება" className="" />
    </Container>
  );
};

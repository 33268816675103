import styled from "styled-components";

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & .content {
    flex: 1;
  }
`;

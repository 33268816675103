import React, { useState } from "react";
import {
  QAContentWrapper,
  QAAnswer,
  QAContainer,
  QANumber,
  QAQuestionWrapper,
  QAQuestion,
  ChevronIcon,
} from "./styles";
import { usePlatform } from "../../hooks";
import { ChevronDirection } from "../../icons/IconChevron";

type Question = {
  number: string;
  title: string;
  answer: string;
};
type Props = {
  question: Question;
  onClick: () => void;
  isExpanded: boolean;
};

export const Question = ({ question, onClick, isExpanded }: Props) => {
  const platform = usePlatform();
  return (
    <QAContainer platform={platform}>
      <QANumber>{question.number}</QANumber>
      <QAContentWrapper>
        <QAQuestionWrapper onClick={onClick}>
          <QAQuestion platform={platform}>{question.title}</QAQuestion>
          <ChevronIcon
            direction={
              isExpanded ? ChevronDirection.DOWN : ChevronDirection.LEFT
            }
          />
        </QAQuestionWrapper>
        {isExpanded && (
          <QAAnswer platform={platform}>{question.answer}</QAAnswer>
        )}
      </QAContentWrapper>
    </QAContainer>
  );
};

/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Phoneicon2 = ({ className }) => {
  return (
    <svg
      className={`phoneicon-2 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M1.42822 3.99965C1.42822 8.73337 5.26594 12.5711 9.99965 12.5711H11.2854C11.6264 12.5711 11.9534 12.4356 12.1945 12.1945C12.4356 11.9534 12.5711 11.6264 12.5711 11.2854V10.5014C12.5711 10.2065 12.3705 9.94937 12.0842 9.87794L9.55679 9.24594C9.30537 9.18308 9.04137 9.27736 8.88651 9.48422L8.33222 10.2231C8.17108 10.4379 7.89279 10.5328 7.64079 10.4402C6.7053 10.0963 5.85574 9.55313 5.15095 8.84835C4.44617 8.14356 3.90301 7.294 3.55908 6.35851C3.46651 6.10651 3.56137 5.82822 3.77622 5.66708L4.51508 5.11279C4.72251 4.95794 4.81622 4.69337 4.75337 4.44251L4.12137 1.91508C4.08659 1.77605 4.00635 1.65262 3.8934 1.56442C3.78044 1.47621 3.64125 1.42827 3.49794 1.42822H2.71394C2.37294 1.42822 2.04592 1.56368 1.8048 1.8048C1.56368 2.04592 1.42822 2.37294 1.42822 2.71394V3.99965Z"
        fill="#1F2937"
        stroke="#1F2937"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.714286"
      />
    </svg>
  );
};

import styled, { css } from "styled-components";
import { Platform } from "../../hooks/usePlatform";
import { Button } from "../Button";
import { IconChevron } from "../../icons";

export const Container = styled.div<{ platform: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 100%;
  padding-bottom: 128px;
  padding-top: 220px;
  background-color: var(--gray-100);

  ${({ platform }) =>
    platform !== Platform.Web &&
    css`
      align-items: start;
      padding-left: 4.5%;
      padding-right: 4.5%;
    `}
`;

export const Header = styled.h3<{ platform: string }>`
  color: var(--gray-800);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44.8px;
  white-space: nowrap;
  padding-bottom: 36px;
  width: fit-content;

  ${({ platform }) =>
    platform !== Platform.Web &&
    css`
      font-size: 24px;
      line-height: 33.6px;
      padding: 48px 24px 36px 24px;
      text-wrap: wrap;
    `}
`;

export const QuestionsWrapper = styled.div<{ platform: string }>`
  border-radius: 12px;
  width: fit-content;
  margin-bottom: 32px;

  ${({ platform }) =>
    platform !== Platform.Web &&
    css`
      min-width: 358px;
      margin: auto;
    `}
`;

export const QAContainer = styled.div<{ platform: string }>`
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  gap: 48px;
  left: 2px;
  box-shadow: 0px 0px 8px #0000001a;
  padding: 40px 56px;
  position: relative;
  top: 0;
  width: 1040px;

  ${({ platform }) =>
    platform !== Platform.Web &&
    css`
      width: 100%;
      flex-direction: column;
      padding: 32px 16px;
      gap: 8px;
    `}
`;

export const QANumber = styled.div`
  color: var(--gray-500);
  font-family: "Inter", Helvetica;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 50.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;
export const QAContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding: 3px 0px 0px;
`;

export const QAQuestionWrapper = styled.div`
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export const QAAnswer = styled.div<{ platform: string }>`
  color: var(--gray-600);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: 760px;

  ${({ platform }) =>
    platform !== Platform.Web &&
    css`
      font-size: 14px;
      line-height: 19.6px;
      width: fit-content;
      width: 83%;
    `}
`;
export const QAQuestion = styled.h5<{ platform: string }>`
  color: var(--gray-800);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  width: 788px;

  ${({ platform }) =>
    platform !== Platform.Web &&
    css`
      font-size: 14px;
      line-height: 19.6px;
      width: 85%;
    `}
`;
export const ChevronIcon = styled(IconChevron)`
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  padding: 4px;
  position: relative;
  width: 32px;
  height: 32px;
`;
export const ViewMoreBtn = styled(Button)<{ platform: string }>`
  ${({ platform }) =>
    platform !== Platform.Web &&
    css`
      margin-top: 24px;
    `}
`;
